import "../css/style.scss";

$(function () {
  // ハンバーガーメニューの処理
  let isScrollLock = false;
  let scrollTop;

  $(".js-hamburger").on("click", function () {
    $(this).toggleClass("is-active");
    $(".js-hamburger-menu").toggleClass("is-active");
    $(".js-nav-button").toggleClass("is-hide");

    if (!isScrollLock) {
      scrollTop = $(window).scrollTop();
      $("html, body").css({ position: "fixed", top: -scrollTop });

      isScrollLock = true;
    } else {
      $("html, body").css({ position: "static", top: "0" });
      $("html,body").scrollTop(scrollTop);

      isScrollLock = false;
    }
  });

  $(".js-hamburger-menu-link").on("click", function () {
    $(".js-hamburger").toggleClass("is-active");
    $(".js-hamburger-menu").toggleClass("is-active");
    $(".js-nav-button").toggleClass("is-hide");

    $("html, body").css({ position: "static", top: "0" });
    $("html,body").scrollTop(scrollTop);

    isScrollLock = false;
  });

  // メニューの表示処理
  var pos = 0;

  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 50) {
      if ($(this).scrollTop() < pos) {
        //上にスクロールしたとき
        $(".js-header").removeClass("is-hide");
      } else {
        //下にスクロールしたとき
        $(".js-header").addClass("is-hide");
      }
    }

    pos = $(this).scrollTop();
  });

  // AOS(スクロールライブラリ)のinit
  AOS.init({
    duration: 1000,
    once: true,
  });
});
